<template>
  <b-sidebar
    id="add-new-category-sidebar"
    :visible="isEditBussinessSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-bussiness-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Update Bussiness Challenge
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <b-overlay
        :show="loading"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="bussinessData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="name"
                  @input.native="onChangeTitle"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Slug -->
            <validation-provider
              #default="validationContext"
              name="Slug"
              rules="required"
            >
              <b-form-group
                label="Slug"
                label-for="slug"
              >
                <b-form-input
                  id="slug"
                  v-model="bussinessData.slug"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Slug"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Region -->
            <validation-provider
              #default="validationContext"
              name="Region"
              rules="required"
            >
              <b-form-group
                label="Region"
                label-for="region"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="bussinessData.region_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regionOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="region"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          <!-- Transalate -->
          <label for="description-form">Options for translate each region</label>
            <transition-group
                id="description-form"
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="0"
            >
                <div
                  v-for="index in countDescription"
                  :key="index"
                >
                  <!-- repeater from -->
                  <b-col cols="12" class="mb-2">
                    <div class="border rounded p-2">
                      <div
                        v-if="arrayDescription.length > 1 && index !== 1"
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItemDescription(index - 1)"
                        />
                      </div>
                      <b-row>
                        <!-- Language -->
                        <b-col md="12">
                          <b-form-group
                            label="Language"
                            label-for="product-add-region"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Language"
                              rules="required"
                            >
                              <v-select
                                id="region-edit-region"
                                v-model="arrayDescription[index - 1].region_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="languageOptions"
                                :clearable="false"
                                :disabled="index === 1"
                                :selectable="(option) => {
                                  return !arrayDescription.find(x => x.region_id.value === option.value)
                                }"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Name -->
                        <b-col md="12">
                          <b-form-group
                            label="Name"
                            label-for="product-add-title"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Name"
                              rules="required"
                            >
                              <b-form-input
                                id="product-add-title"
                                v-model="arrayDescription[index - 1].name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </div>
              </transition-group>
            <b-col
              cols="12"
              class="mt-2"
              >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemFormDescription"
              >
                Add description form
              </b-button>
            </b-col>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Update
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, 
  BFormInput, BFormInvalidFeedback, 
  BButton, BFormCheckbox, BOverlay,
  BCol, BCardBody, BRow
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as utils from '@/utils/utils'
import * as config from '@/utils/config'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCol,
    BRow,
    BCardBody,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditBussinessSidebarActive',
    event: 'update:is-edit-bussiness-sidebar-active',
  },
  props: {
    isEditBussinessSidebarActive: {
      type: Boolean,
      required: true,
    },
    bussinessData: {
      type: Object,
      required: true,
    },
    currentArrayDescription: {
      type: Array,
      required: true,
    },
    currentCountDescription: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  watch: {
    isEditBussinessSidebarActive(value){
      if(!value){
        return
      }
      this.arrayDescription = this.currentArrayDescription
      this.countDescription = this.currentCountDescription
    }
  },
  computed: {
    ...mapGetters('app', ['regionOptions', 'languageOptions']),
  },
  methods: {
    addNewItemInItemFormDescription() {
      this.arrayDescription.push({
        name: '',
        description: '',
        region_id: '',
      })
      this.countDescription += 1
    },
    removeItemDescription(index) {
      this.arrayDescription.splice(index, 1)
      this.countDescription -= 1
    },
    onChangeTitle() {
      this.bussinessData.slug = utils.toSlug(this.bussinessData.name)
    },
  },
  setup(props, { emit }) {
    const arrayDescription = ref([])
    const countDescription = ref(0)
    const toast = useToast()
    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      const tranformData = {
        name: props.bussinessData.name,
        slug: props.bussinessData.slug,
        second_name: props.bussinessData.name,
        is_show: props.bussinessData.is_show,
        is_active: props.bussinessData.is_active,
        region_id: props.bussinessData.region_id,
        metadata: arrayDescription.value
      }
      store.dispatch('bussiness/updateBussiness', { id: props.bussinessData.id, bussiness: tranformData })
        .then(() => {
          loading.value = false
          emit('refetch-data')
          emit('update:is-edit-bussiness-sidebar-active', false)
        }).catch(e => {
          loading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const blankCategory = {
      imagePopularHeader: [],
      imagePopularCarousel: [],
      imageSolutionHeader: [],
      imageSolutionCarousel: [],
      name: '',
      second_name: '',
      second_slug: '',
      slug: '',
    }
    const categoryData = ref(JSON.parse(JSON.stringify(blankCategory)))
    const resetuserData = () => {
      categoryData.value = JSON.parse(JSON.stringify(blankCategory))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      toast,
      categoryData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      config,
      loading,
      arrayDescription,
      countDescription
    }
  },
}
</script>

  <style lang="scss">

  #add-new-category-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
